<template>
  <div class="testSortAdd-container">
    <el-drawer
      class="testSortAdd-drawer"
      :title="
        type == 'add' ? level == 1 ? '新增质检分类' : level == 2 ? '新增问题点' : '新增问题说明' : level == 1 ? '编辑质检分类' : level == 2 ? '编辑问题点' : '编辑问题说明'
      "
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="drawer-container">
        <el-form
          :model="numberValidateForm"
          ref="numberValidateForm"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item
            :label="
              level == 1
                ? '质检分类名称'
                : level == 2
                ? '问题点名称'
                : '问题说明'
            "
            prop="name"
            :rules="[
              {
                required: true,
                message: `${level == 3 ? '问题说明' : '名称'}不能为空`
              },
              { max: 100, message: '名称长度小于100 个字符' }
            ]"
          >
            <el-input
              :autosize="{ minRows: 4, maxRows: 4 }"
              type="textarea"
              v-model="numberValidateForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="level == 3"
            label="问题扣分"
            prop="pointDeduction"
            :rules="[{ required: true, message: `分数不能为空` }]"
          >
            <el-input
              class="common-input-FEN"
              type="number"
              v-model="numberValidateForm.pointDeduction"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="level == 3"
            label="扣款金额"
            prop="amountDeduction"
            :rules="[{ required: true, message: `金额不能为空` }]"
          >
            <el-input
              class="common-input-YUAN"
              type="number"
              v-model="numberValidateForm.amountDeduction"
            ></el-input>
          </el-form-item>
          <div class="formBtn">
            <el-button class="common-screen-btn" @click="() => { this.$emit('handleClose') }">取消</el-button>
            <el-button
              class="common-screen-btn"
              type="primary"
              @click="submit()"
              >确认</el-button
            >
          </div>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { addQuality, editQuality } from "../../../service/service.js";
export default {
  props: ["drawer"],
  data() {
    return {
      numberValidateForm: {},
      type: "",
      level: 1
    };
  },
  methods: {
    getInfo(params, row) {
      if (params.type == "add") {
        this.numberValidateForm = {
          parentId: params.id,
          type: "quality"
        };
      } else {
        if (params.level == 3) {
          this.numberValidateForm = {
            id: params.id,
            type: "quality",
            name: row.name,
            pointDeduction: row.pointDeduction || '',
            amountDeduction: row.amountDeduction || ''
          };
        } else {
          this.numberValidateForm = {
            id: params.id,
            type: "quality",
            name: row.name
          };
        }
      }
      this.level = params.level;
      this.type = params.type;
      if (this.$refs["numberValidateForm"]) {
        this.$refs["numberValidateForm"].resetFields();
      }
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    async submit(done) {
      let numberValidateForm = { ...this.numberValidateForm };
      this.$refs["numberValidateForm"].validate(async (valid) => {
        if (valid) {
          if (this.type == "add") {
            await addQuality(numberValidateForm);
          } else {
            await editQuality(numberValidateForm);
          }
          this.$message.success("新增成功");
          this.$emit("handleClose", done);
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.testSortAdd-drawer {
  .formBtn {
    text-align: center;
    margin-top: 80px;
  }
  .upload-icon-input {
    width: 88px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .drawer-container {
    padding: 0 60px 0 24px;
    text-align: left;
  }

  .upload-icon-container {
    display: inline-block;
    position: relative;
    width: 88px;
    height: 40px;

    .common-screen-btn {
      margin: 0;
    }
  }

  // /deep/ .el-drawer__open .el-drawer.rtl {
  //   padding-right: 60px !important;
  // }
}
</style>
